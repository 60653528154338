import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContactForm from '../components/ContactForm'
import SEO from '../components/SEO'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'



const Why = () => {
  const postNode = {
    title: `Why Volunteer - ${config.siteTitle}`,
  }
  const data = useStaticQuery(graphql`
   query whyQuery {
    contentfulAsset(title: {eq: "fire-teamwork"}) {
      fluid(maxWidth: 500, quality: 80) {
        src
        aspectRatio
        srcSet
        sizes
      }
    }
  }
  
    `)



  return (
    <Layout>
      <Helmet>
        <title>{`Why Volunteer For Warren County Fire Departments - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="why-volunteer" customTitle />

      <Container>
        <h1 className="homeH1">WHY JOIN THE VOLUNTEER FIRE SERVICE?</h1>
        
        <p className="topMargin"><strong>Support your local community</strong></p>

        <p className="topMargin">Warren County volunteer fire departments are looking for dedicated volunteers like you. You do not need any prior experience. Free training is provided. </p>

        <p className="topMargin">Have you always wanted to serve your community? Have you always wanted to help save lives in a fire or motor vehicle accident?</p>
      
        <Img fluid={data.contentfulAsset.fluid} placeholderClassName="trey" className="topMargin" />

        <p className="topMargin">From fighting fires to responding to medical emergencies and supporting community events or administrative duties, the volunteer fire service is very diverse.</p>

        <p className="topMargin">Warren County volunteer fire departments have many ways you can lend a hand.</p>

        <p className="topMargin">To learn more about how you can help, fill out the <a href="/how-to-join/">contact form.</a></p>


      </Container>
    </Layout>
  )
}

export default Why
